<template>
  <div class="qixiang">

    <dialog id="opdog">
      <span @click="iosdd"
            style="width: 30px;
      height: 30px;
      background-color: aliceblue;
      display: block;text-align: center;
      line-height: 30px;
      background-color: rgba(255, 0, 0, 0.719);
      border-radius: 50%;
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;">X</span>
      <img src="../assets/goin3.png"
           width="1000px"
           alt="">
    </dialog>
    <div class="top">
      <span>气象科学STEM系列课程</span>
      <div>
        <el-input placeholder="请输入课程名称"
                  v-model="name">
          <i class="el-icon-search el-input__icon"
             slot="suffix"
             @click="handleIconClick">
          </i>
        </el-input>
      </div>
    </div>
    <div class="menu">
      <div class="type">
        <span :class="{ active: type == 1 }"
              @click="typeClickHandler(1)">课程PPT</span>
        <span :class="{ active: type == 2 }"
              @click="typeClickHandler(2)">科普视频</span>
      </div>
      <el-menu class="el-menu-vertical-demo">
        <el-menu-item :class="{ 'is-active': group == menu.id }"
                      :index="index + ''"
                      v-for="(menu, index) in menuList"
                      :key="menu.id"
                      @click="menuClickHandler(menu.id)">
          <span slot="title">{{ menu.name }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>在线学习</el-breadcrumb-item>
        <el-breadcrumb-item>{{ activeGroup }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="html">
        <div class="box"
             v-for="lesson in lessonList"
             :key="lesson.id">
          <div :class="lesson.free ? 'vip' : 'school'"
               @click="showPpt(lesson)">
            <img :src="lesson._picture"
                 alt="" />
            <span>{{ lesson.name }}</span>
          </div>
        </div>
      </div>
      <el-pagination :hide-on-single-page="true"
                     :current-page="currentPage"
                     :page-size="10"
                     layout="total, prev, pager, next, jumper"
                     :total="total"
                     @current-change="currentChangeHandler" />
    </div>
  </div>
</template>

<script>
import { $lesson } from "@/services";
import { ROOT } from "@/services/base";
export default {
  name: "OnlineStudy",
  data () {
    return {
      type: 1,
      group: "",
      name: "",
      menuList: [],
      lessonList: [],
      currentPage: 1,
      total: 0,
    };
  },
  computed: {
    activeGroup () {
      let tar =
        this.group &&
        this.menuList &&
        this.menuList.find((menu) => menu.id == this.group); 
      return tar && tar.name;
    },
  },
  mounted () {
    this.group = this.$route.params.group;
    this.type = this.$route.params.type || 1;
    this.getMenuList().then(() => {
      let filter = {
        type: this.type,
      };
      if (this.group) {
        filter.group = this.group;
      }
      this.getLessonListHelper(filter);
    });
  },
  methods: {
    iosdd () {
      let a = document.getElementById('opdog')
      a.close();
    },
    currentChangeHandler (page) {
      this.currentPage = page;
      this.getLessonListHelper({
        name: this.name,
        type: this.type,
        group: this.group,
      });
    },
    handleIconClick () {
      this.currentPage = 1;
      this.getLessonListHelper({
        name: this.name,
        type: this.type,
        group: this.group,
      });
    },
    typeClickHandler (type) {
      this.type = type;
      this.name = "";
      this.currentPage = 1;
      this.getLessonListHelper({ type, group: this.group });
    },
    showPpt (ov) {
      let id = ov.id
      if (ov.free === 1) {
        let a = document.getElementById('opdog')
        // a.show();
 
        a.showModal();
      } else {
        let name;
        if (this.type === 1) {
          name = "study";
        } else {
          name = "video";
        }
        this.$router.push({
          name,
          query: {
            id,
          },
        });
      }
    },
    getMenuList () {
      return $lesson.getMenuList().then((res) => {
        if (res.code === 0) {
          this.menuList = res.data;
          this.group = this.group || res.data[0].id;
        }
      });
    },
    getLessonListByType (type) {
      return this.getLessonListHelper({ type });
    },
    getLessonListHelper (filter) {
      return $lesson
        .getLessonList({ ...filter, page: this.currentPage, limit: 9 })
        .then((res) => {
          if (res.code === 0) {
            this.total = res.data.dataCount;
            this.lessonList = res.data.dataList.map(this.lessonParseHelper);
          }
        });
    },
    lessonParseHelper (lesson) {
      return {
        ...lesson,
        _picture: `${ROOT}/${lesson.kc_img}`,
      };
    },
    menuClickHandler (group) {
      this.group = group;
      return this.getLessonListHelper({ group, type: this.type });
    },
  },
};
</script>

<style lang="less">
.qixiang {
  width: 1280px;
  margin: 0 auto;
  padding: 10px 0;
  > .top {
    margin: 20px 0;
    padding: 10px 20px;
    background-color: #03a9f4;
    > span {
      display: inline-block;
      padding-top: 10px;
    }
    > div {
      display: inline-block;
      float: right;
      margin-right: 20px;
    }

    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }

  > .menu {
    width: 260px;
    float: left;
    margin-right: 20px;

    > .type {
      margin-bottom: 10px;
      text-align: center;

      > span {
        display: inline-block;
        width: 50%;
        padding: 20px 0;
        border: 1px solid #ccc;
        cursor: pointer;
        box-sizing: border-box;
        height: 58px;
        float: left;

        // &:last-child{
        //     border-left: none;
        // }

        &.active {
          background-color: #03a9f4;
          color: white;
          border: none;
        }
      }

      &::after {
        content: '';
        display: block;
        clear: both;
      }
    }
  }

  > .content {
    width: 1000px;
    float: left;

    > .el-breadcrumb {
      padding: 20px 30px;
      background: #e5e5e5;
      border-radius: 10px 10px 0px 0px;
    }

    > .html {
      background: #ffffff;
      border: 1px solid #e5e5e5;
      box-shadow: 0px 5px 10px 0px rgba(204, 204, 204, 0.3);
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: flex-start;
      > .box {
        width: 33.3333333333%;
        text-align: center;
        padding: 10px;
        > div {
          position: relative;
          cursor: pointer;
          border: 1px solid #e5e5e5;
          > img {
            width: 100%;
            height: 170px;
            object-fit: cover;
            object-position: center;
          }
          > span {
            display: block;
            padding: 15px;
          }

          &.vip::after {
            content: '';
            position: absolute;
            right: -3px;
            top: -3px;
            width: 80px;
            height: 80px;
            background-image: url('../assets/image/huiyuanban.png');
            background-repeat: round;
          }
          &.school::after {
            content: '';
            position: absolute;
            right: -3px;
            top: -3px;
            width: 80px;
            height: 80px;
            background-image: url('../assets/image/xiaoyuanban.png');
            background-repeat: round;
          }
        }
      }

      &::after {
        content: '';
        display: block;
        clear: both;
      }
    }
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  .el-submenu__title {
    font-size: 18px;
    background-color: #e5e5e5;
    border-bottom: 1px solid #d6d6d6 !important;
  }
  .el-menu-item {
    font-size: 16px;
  }
}
</style>